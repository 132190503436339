<template>
  <div class="reservation">
      <b-row>
          <b-col sm="12">
            <div class="reservationStatus text-center" v-if="reservation !== undefined">
              <span v-if="reservation.status == 'booked'"></span><!--niente da segnalare-->
              <p class="mb-4" v-else-if="reservation.status == 'canceled'">
                <b-alert class="d-inline" variant="danger" show><b>Questa prenotazione è stata cancellata e pertanto non più valida.</b></b-alert>
                <br><br>
              </p><!--prenotazione cancellata-->
              <p class="mb-4" v-else>
                <b-alert class="d-inline" variant="danger" show><b>Questa prenotazione presenta un problema e potrebbe non essere garantita, per favore contattare subito l'assistenza.</b></b-alert>
                <br><br>
              </p><!--c'è stato un problema-->
            </div>

            <div class="reservationStatus text-center" v-if="route != 'confirm' && reservation && reservation.doubleCheck && reservation.status == 'booked'">
              <p class="mb-4">
                <br/><br/>
                <b-alert class="d-inline" :variant="reservation.doubleCheck.variant" show><b>
                  {{reservation.doubleCheck.text}}
                </b></b-alert>
                <br><br>
              </p><!--c'è stato un problema-->
            </div>

            <div class="panel-white p-0">
                <div class="panel-content p-0">
                    <div class="reservation-resume">

                        <!-- solo se sto navigando il dettaglio con la rotta /prenotazioni -->
                        <div style="width:100%;" class="text-center mt-2 mb-2" v-if="this.$route.name == 'reservations'">
                            <div class="buttons">
                                <a class="btn btn-secondary" href="#" @click="print()">Stampa la prenotazione</a>
                            </div>
                        </div>

                        <div v-if="!reservation || (reservation && reservation.status == 'booked')" class="reservation-resume-message">
                          <div class="checkout-message" v-if="this.$route.name == 'checkout'">
                              Complimenti, ottima scelta! Ancora un paio di click e avrai concluso
                              <span v-if="mixinGetIntegrationInfo().mode == 'booking'">la tua prenotazione</span>
                              <span v-if="mixinGetIntegrationInfo().mode == 'boxset'">il tuo acquisto</span>
                          </div>

                          <!-- confirm only -->
                          <div class="confirm-message" v-if="Object.keys(guest).length && this.$route.name == 'confirm'">
                              <h1 class="confirm-message-heading">
                                  Complimenti {{guest.guestFirstName}} {{guest.guestLastName}}!
                                  <br>
                                  <span class="uppercase">la tua prenotazione è confermata</span>
                              </h1>

                              <p>
                                Abbiamo appena inviato un'email con la <b>conferma di prenotazione e il voucher per fare il check-in in hotel</b> all'indirizzo email del viaggiatore <b>{{guest.guestEmail}}</b>.
                                Non esitare a contattarci per qualsiasi informazione o supporto.
                                Ci prenderemo cura, come promesso, di fare un secondo check con la struttura prima del giorno del tuo arrivo per assicurarci che sia tutto ok e pronto per il tuo soggiorno.

                              </p>


                              <p>
                                Non esitare a contattarci per qualsiasi informazione o supporto.
                              </p>


                              <div class="buttons">
                                  <a class="btn btn-secondary" href="#" @click="print()">Stampa</a>
                              </div>
                          </div><!-- confirm only -->                          
                        </div>

                        <div class="reservation-resume-left">
                        
                            <h5 class="panel-title">Riepilogo della prenotazione</h5>

                            <div class="toggleReservation" v-b-toggle="['toggle-info-left','toggle-info-right']">
                              <div class="info">
                                <p class="name">{{bookingData.hotelName}}</p>
                                <strong>checkin:</strong> {{checkinDate}}
                                <br>
                                <strong>checkout:</strong> {{checkoutDate}}
                                <br>
                                {{bookingData.numberOfAdults}} <span v-if="bookingData.numberOfAdults > 0">adulti</span><span v-else>adulto</span>
                                <span v-if="bookingData.numberOfChildren == 1">{{bookingData.numberOfChildren}} bambino</span>
                                <span v-if="bookingData.numberOfChildren > 1">{{bookingData.numberOfChildren}} bambini</span>

                                <hr />

                                <div class="policyCancellazione">
                                  <strong>Policy di cancellazione</strong>
                                  <!--cancellabile-->
                                  <div v-if="bookingData.refundable">
                                      <p>
                                        Se lo desideri è possibile cancellare gratuitamente questa prenotazione entro il: <b>{{bookingData.refundableBefore}}</b> (gg-mm-aaaa hh:mm:ss)
                                      </p>
                                      <p>
                                        Se dovessi cancellare questa prenotazione, verrà riattivato il voucher Salabam usato con lo stesso valore e la stessa data di scadenza (il viaggio deve essere effettuato entro la data di validità).
                                      </p>
                                      <p v-if="mixinGetIntegrationInfo().welfare == true">
                                        <b>Attenzione! Il buono sconto non viene riaccreditato sui conti di welfare aziendale e non può essere cumulato con il credito welfare.</b>
                                      </p>
                                  </div>
                                  <!--non cancellabile-->
                                  <div v-else>
                                    <p>
                                      Questa prenotazione non è cancellabile nè modificabile nè rimborsabile.
                                      <!--<br>
                                      Alla luce delle incertezza causate da Covid-19 ti consigliamo di scegliere soluzioni di tipo Rimborsabile.-->
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <span class="toggler">Tutti i dettagli <SvgChevronDownToggler /></span>
                            </div>

                            <!-- confirm only -->
                            <div class="confirm-resume" v-if="Object.keys(guest).length">
                                <strong class="confirm-subtitle">Codici e riferimenti</strong>
                                <div>
                                    <!--Numero di conferma hotel: <b>{{confirmcode}}</b><br>-->
                                    Numero di conferma prenotazione: <b>{{reservation.confirmationNumber}}</b><br>
                                    Numero di itinerario: <b>{{reservation.itinerary_id}}</b><br>
                                    Viaggiatore di riferimento: <b>{{guest.guestFirstName}} {{guest.guestLastName}}</b><br>
                                    Email del viaggiatore: <b>{{guest.guestEmail}}</b><br>
                                    Telefono del viaggiatore: <b>{{guest.guestPhone}}</b><br>
                                </div>
                            </div>


                            <b-collapse id="toggle-info-left">

                                <strong class="confirm-subtitle">Nome della struttura</strong>
                                <div>{{bookingData.hotelName}}</div>
                                <hr>

                                <strong class="confirm-subtitle">Indirizzo</strong>
                                <div>
                                    <SvgPin class="svg-pin" />{{bookingData.hotelAddress + ', ' + bookingData.hotelPostalCode + ' ' + bookingData.hotelCity + ' (' + bookingData.hotelCountryCode + ')'}}
                                </div>
                                <hr>

                                <div v-if="this.$route.name == 'confirm' || this.$route.name == 'reservations'">
                                <strong class="confirm-subtitle">Numero di telefono</strong>
                                <div>{{bookingData.hotelPhone}}</div>
                                <hr>
                                </div>

                                <strong class="confirm-subtitle">Camera</strong>
                                <div>
                                {{bookingData.roomDescription}}<br/>
                                <span v-b-popover.hover.top="'Preferenza letti: richiesta non confermata soggetta a disponibilità'">Preferenza letti: {{bookingData.bedType}}</span>
                                </div>
                                <hr>

                                <strong class="confirm-subtitle">Servizi inclusi</strong>
                                <div>
                                {{ (reservation && reservation.roomValueAdds)? reservation.roomValueAdds : rateAmenities }}
                                </div>
                                <hr>

                                <strong class="confirm-subtitle">Numero di persone</strong>
                                <div>
                                    {{bookingData.numberOfAdults}} <span v-if="bookingData.numberOfAdults > 1">Adulti</span><span v-else>Adulto</span>
                                    <span v-if="bookingData.childs.length !== 0">
                                        , {{bookingData.childs.length}} <span v-if="bookingData.childs.length > 1">Bambini</span><span v-else>Bambino</span>
                                    </span>
                                </div>
                                <hr>

                                <div class="checkin-checkout-box">
                                    <div>
                                    <strong class="confirm-subtitle">Check-in</strong>
                                    <div>{{checkinDate}}<br/>
                                    <span v-if="bookingData.hotelDetails.checkInTime"> dalle {{bookingData.hotelDetails.checkInTime}}</span> <span v-if="bookingData.hotelDetails.checkInEndTime"> alle {{bookingData.hotelDetails.checkInEndTime}}</span></div>
                                    </div>
                                    <div>
                                    <strong class="confirm-subtitle">Check-out</strong>
                                    <div>{{checkoutDate}}<br/>
                                        <span v-if="bookingData.hotelDetails.checkInTime"> entro le {{bookingData.hotelDetails.checkOutTime}}</span>
                                    </div>
                                    </div>
                                </div>

                                <hr>

                                <strong class="confirm-subtitle">Istruzioni per il checkin</strong>
                                <div v-if="bookingData.hotelDetails.specialCheckInInstructions">
                                <p v-html="bookingData.hotelDetails.specialCheckInInstructions"></p>
                                </div>
                                <div v-html="bookingData.hotelDetails.checkInInstructions"></div>
                            
                            </b-collapse>
                        </div>

                        <b-collapse id="toggle-info-right" class="reservation-resume-right">
                            
                            <h5 class="panel-title">Condizioni d'uso</h5>

                            <div>
                              <strong class="confirm-subtitle">Informazioni importanti</strong>
                              <div>
                                <br/>
                                <ol>
                                  <li v-for="(item, index) in bookingData.hotelDetails.specialDetails" v-bind:key="'specialDetails-' + index">
                                    {{ item }}
                                  </li>
                                </ol>

                                <!--<div class="print-only"><PropertyToKnow :details="bookingData.hotelDetails" /></div>-->
                                &nbsp;
                                <PropertyToKnow :details="bookingData.hotelDetails" />
                              </div>
                                
                                <!--<span class="terms-link no-print" @click="$bvModal.show('modal-info')">
                                  &nbsp;
                                  <i>Leggi tutto ...</i>
                                </span>
                                
                                <b-modal id="modal-info" size="lg" hide-footer>
                                    <template v-slot:modal-title>
                                        Informazioni importanti
                                    </template>
                                    <div class="modal-section">
                                      <PropertyToKnow :details="bookingData.hotelDetails" />
                                    </div>
                                </b-modal>-->

                            </div>

                          <hr>

                          <strong class="confirm-subtitle">Informazioni sulla struttura</strong>

                          <div>
                            <br/>
                            <p>
                              {{bookingData.hotelDetails.location}}
                            </p>
                            <div class="print-only"><PropertyInfo :details="bookingData.hotelDetails" /></div>

                            <span class="terms-link no-print" @click="$bvModal.show('modal-property-info')">
                                &nbsp;
                            <i>Leggi tutto ...</i>
                            </span>

                            <b-modal id="modal-property-info" size="lg" hide-footer>
                              <template v-slot:modal-title>
                                Informazioni sulla struttura
                              </template>
                              <div class="modal-section">
                                <PropertyInfo :details="bookingData.hotelDetails" />
                              </div>
                            </b-modal>

                          </div>

                          <hr>

                          <strong class="confirm-subtitle">Misure anti-covid</strong>
                          <br><br>

                          <!--<PropertyCovidAlert v-if="covidAlertTxt != '-'" :details="bookingData.hotelDetails" />-->
                          <PropertyConditions />

                          <hr>
                          <p><strong class="confirm-subtitle">Policy di cancellazione</strong></p>
                          <!--cancellabile-->
                          <div v-if="bookingData.refundable">
                            <div>
                              <b-row>
                                <b-col sm="12">
                                  <p>
                                    Se lo desideri è possibile cancellare gratuitamente questa prenotazione entro il: <b>{{bookingData.refundableBefore}}</b> (gg-mm-aaaa hh:mm:ss)
                                  </p>
                                  <!--<p>
                                    A causa delle possibili incertezze dovute alla normativa Covid, è sempre preferibile che la rimborsabilità sia garantita il più possibile fino a sotto la data di partenza.
                                  </p>-->
                                  <p>
                                    Se dovessi cancellare questa prenotazione, verrà riattivato il voucher Salabam usato con lo stesso valore e la stessa data di scadenza (il viaggio deve essere effettuato entro la data di validità).
                                  </p>

                                </b-col>
                              </b-row>
                            </div>
                          </div>
                          <!--non cancellabile-->
                          <div v-else>
                            <p>Questa prenotazione non è cancellabile nè modificabile nè rimborsabile.</p>
                            <!--<p v-if="this.$route.name != 'confirm'">
                                Alla luce delle incertezze causate da Covid-19 ti consigliamo di scegliere soluzioni di tipo Rimborsabile.
                            </p>-->
                          </div>

                        </b-collapse>

                        <!-- solo se sto navigando il dettaglio con la rotta /prenotazioni -->
                        <div style="width:100%;" class="text-center mt-4" v-if="this.$route.name == 'reservations'">
                            <div class="buttons">
                                <a class="btn btn-secondary" href="#" @click="print()">Stampa la prenotazione</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
          </b-col>
      </b-row>

  </div>
</template>

<script>
import PropertyInfo from './../blocks/property/property-info'
import PropertyToKnow from './../blocks/property/property-toKnow'
//import PropertyCovidAlert from './../blocks/property/property-covid-alert'
import PropertyConditions from './../blocks/property/property-conditions'
import SvgPin from '../svg/pin'
import SvgChevronDownToggler from './../svg/chevron-down-toggler'
import Vue from "vue";
export default {
    name: 'reservation',
    components:
    {
      PropertyInfo,
      PropertyToKnow,
      SvgPin,
      //PropertyCovidAlert,
      PropertyConditions,
      SvgChevronDownToggler,
    },
    props:
    {
      confirmcode: String,
      itinerary_id: String,
      bookingData: Object,
      guest: Object,
      reservation: Object
    },
    methods:{
        formatDate: function(data){
          function pad(s) { return (s < 10) ? '0' + s : s; }
          var d = new Date(data)
          return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('-')
        },
        print: function(){
          window.print()
        },
        rateAmenitiesTxt: function(){
          if(this.bookingData.rateAmenities.length == 0){
            this.rateAmenities = 'solo pernotto'
          }else{
            let amenities = '';
            for (let key in this.bookingData.rateAmenities) {
              if(this.configAmenities[key]) amenities += this.configAmenities[key].label + ', '
            }

            // capitalizzo e tolgo ", " dalla coda (-3 perchè sto accorciando la stringa senza il primo char)
            amenities = amenities.charAt(0).toUpperCase() + amenities.slice(1).substr(0, amenities.length -3)

            this.bookingData.rateAmenitiesTxt = amenities // alza warning, rateAmenitiesTxt lo uso solo qui per passarmelo al backend.. in seguito vedremo
            this.rateAmenities = amenities
          }
        },
    },
    computed:{
      /*checkinDate: function() {
        return this.formatDate(this.bookingData.arrivalDate)
      },
      checkoutDate: function(){
        return this.formatDate(this.bookingData.departureDate)
      },*/
      checkinDate: function() {
        // reservation arriva da una verifica itinerario con api expedia (così se venissero cambiate la date, recuperiamo quelle aggiornate)
        if(this.reservation && this.reservation.checkin) return this.reservation.checkin

        //return this.formatDate(this.bookingData.arrivalDate)
        return this.bookingData.arrivalDate.split("-").reverse().join("-")
      },
      checkoutDate: function(){
        if(this.reservation && this.reservation.checkout) return this.reservation.checkout

        //return this.formatDate(this.bookingData.departureDate)
        return this.bookingData.departureDate.split("-").reverse().join("-")
      },
    },
    data() {
      return {
        //titolareCredito: (Vue.prototype.$config.integration ? Vue.prototype.$config.integration.email : ''),
        configAmenities: Vue.prototype.$config.amenities,
        rateAmenities: '',
        loading: false,
        route: this.$router.currentRoute.name,
        covidAlertTxt: Vue.prototype.$config.guiSettings.covidAlertTxt,
        site: Vue.prototype.$config.site,
      }
    },
    created: function() {
      this.rateAmenitiesTxt();
    },
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../scss/_custom.scss';

    .reservation
    {

        ol>li{
        list-style-type: disc;
        margin-bottom: 15px;
        }

        .reservation-resume
        {
            
            strong
            {
                font-weight:600;
            }
            .panel-title
            {
                margin-bottom:2rem;
            }
            padding:$grid-gutter-width/2;
            @include media-breakpoint-up(lg)
            {
                padding:$grid-gutter-width/4 $grid-gutter-width/2;
            }
        }

        .reservation-resume-message
        {
            width:100%;
            margin-bottom:2rem;
            @include media-breakpoint-up(lg)
            {
                margin-bottom:0;
                margin-top:1rem;
                .checkout-message
                {
                    text-align:left;
                }
            }
        }

        .reservationRefundable
        {
        width:100%;
        margin-top:1rem;
        margin-bottom:1rem;
        text-align:center;
        }

        .reservation-resume-left
        {
            margin-top:1rem;
            padding-bottom:1rem;    
        }

        .reservation-resume-right
        {
            margin-top:1rem;
            padding-bottom:1rem;
        }

        @include media-breakpoint-up(lg)
        {
            .reservation-resume-left
            {
                margin-top:1rem;
                padding-top:$grid-gutter-width/2;
                padding-bottom:$grid-gutter-width/2;    
            }

            .reservation-resume-right
            {
                margin-top:1rem;
                padding-top:$grid-gutter-width/2;
                padding-bottom:$grid-gutter-width/2;
            }
        }

        .confirm-subtitle
        {
        font-size: 19px;
        }

        .checkout-message
        {
        font-size:1rem;
        text-align:center;
        margin-bottom:1.5rem;
        }

        .confirm-message
        {
            font-size:16px;
            margin-bottom:2rem;
            .buttons
            {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                margin-top:2rem;
                a
                {
                    //flex:1;
                    margin:1rem;
                    //max-width: 200px;
                }
            }
        }
        .confirm-message-heading
        {
            text-align:center;
            font-weight: 600;
            font-size:28px;
            margin-bottom:3rem;
            margin-top:2rem;
            font-weight:normal;
            color:$black;
        }
        .confirm-resume
        {
            border:3px solid $gold;
            margin-bottom:3rem;
            padding:0.75rem 1rem;
            b
            {
                font-weight:600;
            }
        }

        .checkin-checkout-box
        {
            display:flex;
            //justify-content: space-between;
            &>div
            {
                flex:50%;
                width:50%;
                padding-right: 1rem;
            }
            }

            .reservation-resume svg
            {
            margin-right: 6px;
            & > * {
                fill:$black;
            }
        }

        .terms-link
        {
        color:$secondary;
        cursor:pointer;
        }

        @include media-breakpoint-up(lg)
        {
            .reservation-resume
            {
                display:flex;
                flex-wrap:wrap;
            }
            .reservation-resume-left
            {
                width:50%;
                padding-right:$grid-gutter-width;
                border-right:1px solid $light-gray;
            }
            .reservation-resume-right
            {
                width:calc(50% - #{$grid-gutter-width});
                margin-left:$grid-gutter-width;
            }
        }

        @include media-breakpoint-up(xl)
        {
            .reservation-resume
            {
            padding:$grid-gutter-width/4 $grid-gutter-width $grid-gutter-width/2 $grid-gutter-width;
            }
        }

        @include media-breakpoint-only(lg)
        {
            .reservation-resume
            {
                padding:0;
            }
        }

        @include media-breakpoint-only(lg)
        {
            .reservation-resume
            {
                padding:0;
            }
        }
        @include media-breakpoint-down(md)
        {
            .reservation-resume-left
            {
                margin-top:0;
                margin-bottom:0;
                padding-bottom:0;
            }
            margin-bottom:1rem;
            #toggle-info-left
            {
                margin-top:3rem;
            }
        }

        .reservationIntro
        {
        text-align: center;
        margin-bottom:2rem;
        font-size: 14px;
        font-weight: 500;
        }
        .toggleReservation
        {
        text-align:center;
        .info
        {
            margin-bottom:1rem;
            padding-top:0;
        }
        .name
        {
            font-weight:600;
            font-size: 18px;
            margin-bottom:1rem;
        }
        .btn
        {
        }
        .policyCancellazione
        {
            font-size: 14px;
            margin-top:1rem;
        }
        .toggler
        {
            font-weight:500;
            svg
            {
            width: 26px;
            margin-left: 4px;          
            }        
        }
        }
        @include media-breakpoint-up(lg)
        {
            #toggle-info-left,
            #toggle-info-right
            {
                display: block !important;
            }
            .toggleReservation
            {
                display:none;
            }
        }
    }
</style>