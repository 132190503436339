var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reservation" },
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { sm: "12" } }, [
            _vm.reservation !== undefined
              ? _c("div", { staticClass: "reservationStatus text-center" }, [
                  _vm.reservation.status == "booked"
                    ? _c("span")
                    : _vm.reservation.status == "canceled"
                    ? _c(
                        "p",
                        { staticClass: "mb-4" },
                        [
                          _c(
                            "b-alert",
                            {
                              staticClass: "d-inline",
                              attrs: { variant: "danger", show: "" },
                            },
                            [
                              _c("b", [
                                _vm._v(
                                  "Questa prenotazione è stata cancellata e pertanto non più valida."
                                ),
                              ]),
                            ]
                          ),
                          _c("br"),
                          _c("br"),
                        ],
                        1
                      )
                    : _c(
                        "p",
                        { staticClass: "mb-4" },
                        [
                          _c(
                            "b-alert",
                            {
                              staticClass: "d-inline",
                              attrs: { variant: "danger", show: "" },
                            },
                            [
                              _c("b", [
                                _vm._v(
                                  "Questa prenotazione presenta un problema e potrebbe non essere garantita, per favore contattare subito l'assistenza."
                                ),
                              ]),
                            ]
                          ),
                          _c("br"),
                          _c("br"),
                        ],
                        1
                      ),
                ])
              : _vm._e(),
            _vm.route != "confirm" &&
            _vm.reservation &&
            _vm.reservation.doubleCheck &&
            _vm.reservation.status == "booked"
              ? _c("div", { staticClass: "reservationStatus text-center" }, [
                  _c(
                    "p",
                    { staticClass: "mb-4" },
                    [
                      _c("br"),
                      _c("br"),
                      _c(
                        "b-alert",
                        {
                          staticClass: "d-inline",
                          attrs: {
                            variant: _vm.reservation.doubleCheck.variant,
                            show: "",
                          },
                        },
                        [
                          _c("b", [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.reservation.doubleCheck.text) +
                                "\n              "
                            ),
                          ]),
                        ]
                      ),
                      _c("br"),
                      _c("br"),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "panel-white p-0" }, [
              _c("div", { staticClass: "panel-content p-0" }, [
                _c(
                  "div",
                  { staticClass: "reservation-resume" },
                  [
                    this.$route.name == "reservations"
                      ? _c(
                          "div",
                          {
                            staticClass: "text-center mt-2 mb-2",
                            staticStyle: { width: "100%" },
                          },
                          [
                            _c("div", { staticClass: "buttons" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "btn btn-secondary",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.print()
                                    },
                                  },
                                },
                                [_vm._v("Stampa la prenotazione")]
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    !_vm.reservation ||
                    (_vm.reservation && _vm.reservation.status == "booked")
                      ? _c(
                          "div",
                          { staticClass: "reservation-resume-message" },
                          [
                            this.$route.name == "checkout"
                              ? _c("div", { staticClass: "checkout-message" }, [
                                  _vm._v(
                                    "\n                            Complimenti, ottima scelta! Ancora un paio di click e avrai concluso\n                            "
                                  ),
                                  _vm.mixinGetIntegrationInfo().mode ==
                                  "booking"
                                    ? _c("span", [
                                        _vm._v("la tua prenotazione"),
                                      ])
                                    : _vm._e(),
                                  _vm.mixinGetIntegrationInfo().mode == "boxset"
                                    ? _c("span", [_vm._v("il tuo acquisto")])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            Object.keys(_vm.guest).length &&
                            this.$route.name == "confirm"
                              ? _c("div", { staticClass: "confirm-message" }, [
                                  _c(
                                    "h1",
                                    { staticClass: "confirm-message-heading" },
                                    [
                                      _vm._v(
                                        "\n                                Complimenti " +
                                          _vm._s(_vm.guest.guestFirstName) +
                                          " " +
                                          _vm._s(_vm.guest.guestLastName) +
                                          "!\n                                "
                                      ),
                                      _c("br"),
                                      _c("span", { staticClass: "uppercase" }, [
                                        _vm._v(
                                          "la tua prenotazione è confermata"
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c("p", [
                                    _vm._v(
                                      "\n                              Abbiamo appena inviato un'email con la "
                                    ),
                                    _c("b", [
                                      _vm._v(
                                        "conferma di prenotazione e il voucher per fare il check-in in hotel"
                                      ),
                                    ]),
                                    _vm._v(
                                      " all'indirizzo email del viaggiatore "
                                    ),
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.guest.guestEmail)),
                                    ]),
                                    _vm._v(
                                      ".\n                              Non esitare a contattarci per qualsiasi informazione o supporto.\n                              Ci prenderemo cura, come promesso, di fare un secondo check con la struttura prima del giorno del tuo arrivo per assicurarci che sia tutto ok e pronto per il tuo soggiorno.\n\n                            "
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      "\n                              Non esitare a contattarci per qualsiasi informazione o supporto.\n                            "
                                    ),
                                  ]),
                                  _c("div", { staticClass: "buttons" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "btn btn-secondary",
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.print()
                                          },
                                        },
                                      },
                                      [_vm._v("Stampa")]
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "reservation-resume-left" },
                      [
                        _c("h5", { staticClass: "panel-title" }, [
                          _vm._v("Riepilogo della prenotazione"),
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle",
                                value: [
                                  "toggle-info-left",
                                  "toggle-info-right",
                                ],
                                expression:
                                  "['toggle-info-left','toggle-info-right']",
                              },
                            ],
                            staticClass: "toggleReservation",
                          },
                          [
                            _c("div", { staticClass: "info" }, [
                              _c("p", { staticClass: "name" }, [
                                _vm._v(_vm._s(_vm.bookingData.hotelName)),
                              ]),
                              _c("strong", [_vm._v("checkin:")]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.checkinDate) +
                                  "\n                              "
                              ),
                              _c("br"),
                              _c("strong", [_vm._v("checkout:")]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.checkoutDate) +
                                  "\n                              "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                              " +
                                  _vm._s(_vm.bookingData.numberOfAdults) +
                                  " "
                              ),
                              _vm.bookingData.numberOfAdults > 0
                                ? _c("span", [_vm._v("adulti")])
                                : _c("span", [_vm._v("adulto")]),
                              _vm.bookingData.numberOfChildren == 1
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.bookingData.numberOfChildren) +
                                        " bambino"
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.bookingData.numberOfChildren > 1
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.bookingData.numberOfChildren) +
                                        " bambini"
                                    ),
                                  ])
                                : _vm._e(),
                              _c("hr"),
                              _c(
                                "div",
                                { staticClass: "policyCancellazione" },
                                [
                                  _c("strong", [
                                    _vm._v("Policy di cancellazione"),
                                  ]),
                                  _vm.bookingData.refundable
                                    ? _c("div", [
                                        _c("p", [
                                          _vm._v(
                                            "\n                                      Se lo desideri è possibile cancellare gratuitamente questa prenotazione entro il: "
                                          ),
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.bookingData.refundableBefore
                                              )
                                            ),
                                          ]),
                                          _vm._v(
                                            " (gg-mm-aaaa hh:mm:ss)\n                                    "
                                          ),
                                        ]),
                                        _c("p", [
                                          _vm._v(
                                            "\n                                      Se dovessi cancellare questa prenotazione, verrà riattivato il voucher Salabam usato con lo stesso valore e la stessa data di scadenza (il viaggio deve essere effettuato entro la data di validità).\n                                    "
                                          ),
                                        ]),
                                        _vm.mixinGetIntegrationInfo().welfare ==
                                        true
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v(
                                                  "Attenzione! Il buono sconto non viene riaccreditato sui conti di welfare aziendale e non può essere cumulato con il credito welfare."
                                                ),
                                              ]),
                                            ])
                                          : _vm._e(),
                                      ])
                                    : _c("div", [
                                        _c("p", [
                                          _vm._v(
                                            "\n                                    Questa prenotazione non è cancellabile nè modificabile nè rimborsabile.\n                                    "
                                          ),
                                        ]),
                                      ]),
                                ]
                              ),
                            ]),
                            _c(
                              "span",
                              { staticClass: "toggler" },
                              [
                                _vm._v("Tutti i dettagli "),
                                _c("SvgChevronDownToggler"),
                              ],
                              1
                            ),
                          ]
                        ),
                        Object.keys(_vm.guest).length
                          ? _c("div", { staticClass: "confirm-resume" }, [
                              _c(
                                "strong",
                                { staticClass: "confirm-subtitle" },
                                [_vm._v("Codici e riferimenti")]
                              ),
                              _c("div", [
                                _vm._v(
                                  "\n                                  Numero di conferma prenotazione: "
                                ),
                                _c("b", [
                                  _vm._v(
                                    _vm._s(_vm.reservation.confirmationNumber)
                                  ),
                                ]),
                                _c("br"),
                                _vm._v(
                                  "\n                                  Numero di itinerario: "
                                ),
                                _c("b", [
                                  _vm._v(_vm._s(_vm.reservation.itinerary_id)),
                                ]),
                                _c("br"),
                                _vm._v(
                                  "\n                                  Viaggiatore di riferimento: "
                                ),
                                _c("b", [
                                  _vm._v(
                                    _vm._s(_vm.guest.guestFirstName) +
                                      " " +
                                      _vm._s(_vm.guest.guestLastName)
                                  ),
                                ]),
                                _c("br"),
                                _vm._v(
                                  "\n                                  Email del viaggiatore: "
                                ),
                                _c("b", [_vm._v(_vm._s(_vm.guest.guestEmail))]),
                                _c("br"),
                                _vm._v(
                                  "\n                                  Telefono del viaggiatore: "
                                ),
                                _c("b", [_vm._v(_vm._s(_vm.guest.guestPhone))]),
                                _c("br"),
                              ]),
                            ])
                          : _vm._e(),
                        _c(
                          "b-collapse",
                          { attrs: { id: "toggle-info-left" } },
                          [
                            _c("strong", { staticClass: "confirm-subtitle" }, [
                              _vm._v("Nome della struttura"),
                            ]),
                            _c("div", [
                              _vm._v(_vm._s(_vm.bookingData.hotelName)),
                            ]),
                            _c("hr"),
                            _c("strong", { staticClass: "confirm-subtitle" }, [
                              _vm._v("Indirizzo"),
                            ]),
                            _c(
                              "div",
                              [
                                _c("SvgPin", { staticClass: "svg-pin" }),
                                _vm._v(
                                  _vm._s(
                                    _vm.bookingData.hotelAddress +
                                      ", " +
                                      _vm.bookingData.hotelPostalCode +
                                      " " +
                                      _vm.bookingData.hotelCity +
                                      " (" +
                                      _vm.bookingData.hotelCountryCode +
                                      ")"
                                  ) + "\n                              "
                                ),
                              ],
                              1
                            ),
                            _c("hr"),
                            this.$route.name == "confirm" ||
                            this.$route.name == "reservations"
                              ? _c("div", [
                                  _c(
                                    "strong",
                                    { staticClass: "confirm-subtitle" },
                                    [_vm._v("Numero di telefono")]
                                  ),
                                  _c("div", [
                                    _vm._v(_vm._s(_vm.bookingData.hotelPhone)),
                                  ]),
                                  _c("hr"),
                                ])
                              : _vm._e(),
                            _c("strong", { staticClass: "confirm-subtitle" }, [
                              _vm._v("Camera"),
                            ]),
                            _c("div", [
                              _vm._v(
                                "\n                              " +
                                  _vm._s(_vm.bookingData.roomDescription)
                              ),
                              _c("br"),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top",
                                      value:
                                        "Preferenza letti: richiesta non confermata soggetta a disponibilità",
                                      expression:
                                        "'Preferenza letti: richiesta non confermata soggetta a disponibilità'",
                                      modifiers: { hover: true, top: true },
                                    },
                                  ],
                                },
                                [
                                  _vm._v(
                                    "Preferenza letti: " +
                                      _vm._s(_vm.bookingData.bedType)
                                  ),
                                ]
                              ),
                            ]),
                            _c("hr"),
                            _c("strong", { staticClass: "confirm-subtitle" }, [
                              _vm._v("Servizi inclusi"),
                            ]),
                            _c("div", [
                              _vm._v(
                                "\n                              " +
                                  _vm._s(
                                    _vm.reservation &&
                                      _vm.reservation.roomValueAdds
                                      ? _vm.reservation.roomValueAdds
                                      : _vm.rateAmenities
                                  ) +
                                  "\n                              "
                              ),
                            ]),
                            _c("hr"),
                            _c("strong", { staticClass: "confirm-subtitle" }, [
                              _vm._v("Numero di persone"),
                            ]),
                            _c("div", [
                              _vm._v(
                                "\n                                  " +
                                  _vm._s(_vm.bookingData.numberOfAdults) +
                                  " "
                              ),
                              _vm.bookingData.numberOfAdults > 1
                                ? _c("span", [_vm._v("Adulti")])
                                : _c("span", [_vm._v("Adulto")]),
                              _vm.bookingData.childs.length !== 0
                                ? _c("span", [
                                    _vm._v(
                                      "\n                                      , " +
                                        _vm._s(_vm.bookingData.childs.length) +
                                        " "
                                    ),
                                    _vm.bookingData.childs.length > 1
                                      ? _c("span", [_vm._v("Bambini")])
                                      : _c("span", [_vm._v("Bambino")]),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("hr"),
                            _c("div", { staticClass: "checkin-checkout-box" }, [
                              _c("div", [
                                _c(
                                  "strong",
                                  { staticClass: "confirm-subtitle" },
                                  [_vm._v("Check-in")]
                                ),
                                _c("div", [
                                  _vm._v(_vm._s(_vm.checkinDate)),
                                  _c("br"),
                                  _vm.bookingData.hotelDetails.checkInTime
                                    ? _c("span", [
                                        _vm._v(
                                          " dalle " +
                                            _vm._s(
                                              _vm.bookingData.hotelDetails
                                                .checkInTime
                                            )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.bookingData.hotelDetails.checkInEndTime
                                    ? _c("span", [
                                        _vm._v(
                                          " alle " +
                                            _vm._s(
                                              _vm.bookingData.hotelDetails
                                                .checkInEndTime
                                            )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]),
                              _c("div", [
                                _c(
                                  "strong",
                                  { staticClass: "confirm-subtitle" },
                                  [_vm._v("Check-out")]
                                ),
                                _c("div", [
                                  _vm._v(_vm._s(_vm.checkoutDate)),
                                  _c("br"),
                                  _vm.bookingData.hotelDetails.checkInTime
                                    ? _c("span", [
                                        _vm._v(
                                          " entro le " +
                                            _vm._s(
                                              _vm.bookingData.hotelDetails
                                                .checkOutTime
                                            )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]),
                            ]),
                            _c("hr"),
                            _c("strong", { staticClass: "confirm-subtitle" }, [
                              _vm._v("Istruzioni per il checkin"),
                            ]),
                            _vm.bookingData.hotelDetails
                              .specialCheckInInstructions
                              ? _c("div", [
                                  _c("p", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.bookingData.hotelDetails
                                          .specialCheckInInstructions
                                      ),
                                    },
                                  }),
                                ])
                              : _vm._e(),
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.bookingData.hotelDetails
                                    .checkInInstructions
                                ),
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-collapse",
                      {
                        staticClass: "reservation-resume-right",
                        attrs: { id: "toggle-info-right" },
                      },
                      [
                        _c("h5", { staticClass: "panel-title" }, [
                          _vm._v("Condizioni d'uso"),
                        ]),
                        _c("div", [
                          _c("strong", { staticClass: "confirm-subtitle" }, [
                            _vm._v("Informazioni importanti"),
                          ]),
                          _c(
                            "div",
                            [
                              _c("br"),
                              _c(
                                "ol",
                                _vm._l(
                                  _vm.bookingData.hotelDetails.specialDetails,
                                  function (item, index) {
                                    return _c(
                                      "li",
                                      { key: "specialDetails-" + index },
                                      [
                                        _vm._v(
                                          "\n                                  " +
                                            _vm._s(item) +
                                            "\n                                "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                              _vm._v(
                                "\n                               \n                              "
                              ),
                              _c("PropertyToKnow", {
                                attrs: {
                                  details: _vm.bookingData.hotelDetails,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("hr"),
                        _c("strong", { staticClass: "confirm-subtitle" }, [
                          _vm._v("Informazioni sulla struttura"),
                        ]),
                        _c(
                          "div",
                          [
                            _c("br"),
                            _c("p", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.bookingData.hotelDetails.location
                                  ) +
                                  "\n                          "
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "print-only" },
                              [
                                _c("PropertyInfo", {
                                  attrs: {
                                    details: _vm.bookingData.hotelDetails,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "terms-link no-print",
                                on: {
                                  click: function ($event) {
                                    return _vm.$bvModal.show(
                                      "modal-property-info"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                               \n                          "
                                ),
                                _c("i", [_vm._v("Leggi tutto ...")]),
                              ]
                            ),
                            _c(
                              "b-modal",
                              {
                                attrs: {
                                  id: "modal-property-info",
                                  size: "lg",
                                  "hide-footer": "",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "modal-title",
                                    fn: function () {
                                      return [
                                        _vm._v(
                                          "\n                              Informazioni sulla struttura\n                            "
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "modal-section" },
                                  [
                                    _c("PropertyInfo", {
                                      attrs: {
                                        details: _vm.bookingData.hotelDetails,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("hr"),
                        _c("strong", { staticClass: "confirm-subtitle" }, [
                          _vm._v("Misure anti-covid"),
                        ]),
                        _c("br"),
                        _c("br"),
                        _c("PropertyConditions"),
                        _c("hr"),
                        _c("p", [
                          _c("strong", { staticClass: "confirm-subtitle" }, [
                            _vm._v("Policy di cancellazione"),
                          ]),
                        ]),
                        _vm.bookingData.refundable
                          ? _c("div", [
                              _c(
                                "div",
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-col", { attrs: { sm: "12" } }, [
                                        _c("p", [
                                          _vm._v(
                                            "\n                                  Se lo desideri è possibile cancellare gratuitamente questa prenotazione entro il: "
                                          ),
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.bookingData.refundableBefore
                                              )
                                            ),
                                          ]),
                                          _vm._v(
                                            " (gg-mm-aaaa hh:mm:ss)\n                                "
                                          ),
                                        ]),
                                        _c("p", [
                                          _vm._v(
                                            "\n                                  Se dovessi cancellare questa prenotazione, verrà riattivato il voucher Salabam usato con lo stesso valore e la stessa data di scadenza (il viaggio deve essere effettuato entro la data di validità).\n                                "
                                          ),
                                        ]),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _c("div", [
                              _c("p", [
                                _vm._v(
                                  "Questa prenotazione non è cancellabile nè modificabile nè rimborsabile."
                                ),
                              ]),
                            ]),
                      ],
                      1
                    ),
                    this.$route.name == "reservations"
                      ? _c(
                          "div",
                          {
                            staticClass: "text-center mt-4",
                            staticStyle: { width: "100%" },
                          },
                          [
                            _c("div", { staticClass: "buttons" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "btn btn-secondary",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.print()
                                    },
                                  },
                                },
                                [_vm._v("Stampa la prenotazione")]
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }