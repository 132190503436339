var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "43",
        height: "43",
        viewBox: "0 0 43 43",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          opacity: "0.3",
          d: "M21.2007 42.4C32.7434 42.4 42.1007 33.0427 42.1007 21.5C42.1007 9.95723 32.7434 0.599976 21.2007 0.599976C9.65793 0.599976 0.300781 9.95723 0.300781 21.5C0.300781 33.0427 9.65793 42.4 21.2007 42.4Z",
          fill: "#CCCCCC",
        },
      }),
      _c("g", { attrs: { filter: "url(#filter-menu-button)" } }, [
        _c("path", {
          attrs: {
            d: "M21.2007 37.4C29.982 37.4 37.1007 30.2813 37.1007 21.5C37.1007 12.7186 29.982 5.59998 21.2007 5.59998C12.4194 5.59998 5.30078 12.7186 5.30078 21.5C5.30078 30.2813 12.4194 37.4 21.2007 37.4Z",
            fill: "white",
          },
        }),
      ]),
      _c("path", {
        staticClass: "arrow",
        attrs: {
          d: "M14.3008 18.8C14.3008 18.4 14.4007 18.1 14.7007 17.8C15.2007 17.3 16.1007 17.3 16.7007 17.8L21.2007 22.3L25.7007 17.8C26.2007 17.3 27.1007 17.3 27.7007 17.8C28.3007 18.3 28.2007 19.2 27.7007 19.8L22.2007 25.3C21.7007 25.8 20.8007 25.8 20.2007 25.3L14.7007 19.8C14.5007 19.5 14.3008 19.1 14.3008 18.8Z",
          fill: "#39414E",
        },
      }),
      _c("defs", [
        _c(
          "filter",
          {
            attrs: {
              id: "filter-menu-button",
              x: "3.30078",
              y: "3.59998",
              width: "37.7999",
              height: "37.8",
              filterUnits: "userSpaceOnUse",
              "color-interpolation-filters": "sRGB",
            },
          },
          [
            _c("feFlood", {
              attrs: { "flood-opacity": "0", result: "BackgroundImageFix" },
            }),
            _c("feColorMatrix", {
              attrs: {
                in: "SourceAlpha",
                type: "matrix",
                values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
              },
            }),
            _c("feOffset", { attrs: { dx: "1", dy: "1" } }),
            _c("feGaussianBlur", { attrs: { stdDeviation: "1.5" } }),
            _c("feColorMatrix", {
              attrs: {
                type: "matrix",
                values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0",
              },
            }),
            _c("feBlend", {
              attrs: {
                mode: "normal",
                in2: "BackgroundImageFix",
                result: "effect1_dropShadow",
              },
            }),
            _c("feBlend", {
              attrs: {
                mode: "normal",
                in: "SourceGraphic",
                in2: "effect1_dropShadow",
                result: "shape",
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }